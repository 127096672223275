
export default {
  name: 'NotificationModal',
  methods: {
    toggleLogin() {
      if(this.$auth.loggedIn) {
        this.$store.commit('SET_NOTIFICATION', false)
        this.$store.commit('SET_PROFILE', true)
      }
    }
  }
}
